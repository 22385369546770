import { useEffect, useState } from 'react';
import { useLocation } from 'wouter';

// Define types for query parameters
interface QueryParams {
  [key: string]: string;
}

// Define type for the setParams function
type SetParamsFunction = (newParams: Partial<QueryParams>) => void;

/**
 * Custom hook to manage URL query parameters
 * @returns [QueryParams, SetParamsFunction] Tuple containing current query parameters and setter function
 */
const useQueryParams = (): [QueryParams, SetParamsFunction] => {
  const [location] = useLocation();
  const [queryParams, setQueryParams] = useState<QueryParams>({});

  useEffect(() => {
    // Use window.location.search to get the query string
    const searchParams = new URLSearchParams(window.location.search);
    const params: QueryParams = {};

    // Convert URLSearchParams to a plain object
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    setQueryParams(params);
  }, [location]); // Re-run when location changes

  /**
   * Helper function to update query params and navigate
   * @param newParams - Object containing new parameters to set or update
   */
  const setParams: SetParamsFunction = (newParams) => {
    const searchParams = new URLSearchParams(window.location.search);
    
    // Update or add new parameters
    Object.entries(newParams).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, String(value));
      }
    });

    // Construct new URL with updated query parameters
    const newSearch = searchParams.toString();
    const newPath = `${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;
    
    // Update browser history
    window.history.pushState(null, '', newPath);
  };

  return [queryParams, setParams];
};

// Example usage:
// const [params, setParams] = useQueryParams();
// setParams({ page: '2', search: 'example' });
// console.log(params.page); // Access specific parameter

export default useQueryParams;