// src/Tiptap.tsx
import { EditorProvider } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { VoicelineExtension } from '../../plugins/voiceline';
import TipTapMenu from './TipTapMenu';
import { SentenceSegmenter } from '../../plugins/segmenter';
import { PauseValue } from '../../plugins/pause';
import { useMemo } from 'react';
import useVoiceStore from '../../hooks/useVoiceStore';
import VoiceJoiner from './VoiceJoiner';

const content = '<p>Yes. No. Maybe.</p>';

const Tiptap = () => {
  const addVoice = useVoiceStore(state => state.addVoice);
  const setIntervalAudio = useVoiceStore(state => state.setIntervalAudio);

  // define your extension array
  const extensions = useMemo(
    () => [
      StarterKit,
      PauseValue.configure({
        makeIntervalAudio: (one, two, duration) => {
          setIntervalAudio(one, two, duration);
        },
      }),
      VoicelineExtension.configure({
        HTMLAttributes: {
          class: 'my-comment',
        },
        onCommentActivated: commentId => {},
      }),
      SentenceSegmenter.configure({
        onInsertSegment(id, text) {
          addVoice(text || '', null, id);
        },
      }),
    ],
    [addVoice, setIntervalAudio],
  );

  return (
    <div className="bg-white p-5">
      <EditorProvider extensions={extensions} content={content}>
        <TipTapMenu />
        <VoiceJoiner />
      </EditorProvider>
    </div>
  );
};

export default Tiptap;
