import React, { useEffect } from 'react';
import AppleButton from '../buttons/AppleButton';
import GoogleButton from '../buttons/GoogleButton';
import { useLocation, useRoute } from 'wouter';
import { useInfiniteQuery } from '@tanstack/react-query';
import supabase from '../../lib/supabase';
import { usePanelData } from '../../hooks/usePanelData';
import UserAvatar from '../common/UserAvatar';
import { format } from 'date-fns-tz';

// Format given date or current date in human readable format - uses local timezone by default
const formatDate = (date = new Date()) => {
  return format(date, 'MMM dd, yyyy');
};

const PAGE_SIZE = 10;

const formatDuration = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const UserProfileWithPodcasts: React.FC = () => {
  const [, params] = useRoute<{ slug: string }>('/panels/:slug');

  const [, setLocation] = useLocation();

  const { data: panelData, isLoading: panelLoading } = usePanelData(params?.slug!);

  const {
    data: uploadsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: uploadsLoading,
    error
  } = useInfiniteQuery({
    queryKey: ['member-uploads', panelData?.id],
    queryFn: async ({ pageParam = 0 }) => {
      const { data, error } = await supabase.rpc('get_group_uploads', {
        // TODO: Bring userid back?
        p_user_id: /*userData?.id ||*/ 'b2a2a6f3-fd6e-42f4-a133-328083b22a20',
        p_group_id: panelData?.id,
        p_limit: PAGE_SIZE,
        p_offset_val: pageParam * PAGE_SIZE
      });
      
      if (error) throw error;
      return data;
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.length === PAGE_SIZE ? allPages.length : undefined;
    },
    initialPageParam: 0,
    enabled: !!panelData?.id
  });

  useEffect(() => {
    if(!panelData) return;
    document.title = `${panelData.name} | Jacket.fm`;
  }, [panelData]);

  if (panelLoading || uploadsLoading) return <>Loading...</>;
  if (error) return <>Error loading uploads</>;

  const allUploads = uploadsData?.pages.flat() || [];

  return (
    <div className="min-h-screen p-2 md:p-4">
    <div className="max-w-6xl mx-auto">
      {/* Add this new div for app download links */}
      <div className="flex flex-row px-2 md:px-0 mb-4 items-center justify-between">
        <a href="/" title='Jacket.fm home'>
            <img alt="Jacket.fm logo" src="/favicon.png" className="w-[38px] h-[38px]" />
        </a>
        <div className='flex flex-row md:gap-x-4'>
          <AppleButton collapsible />
          <GoogleButton collapsible />
        </div>
      </div>

      {/* Profile Section */}
      <div className="bg-white rounded-lg p-6 mb-8">
        <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
          {/* Avatar */}
          <img
            src={panelData.avatar || 'https://yt3.googleusercontent.com/GgzUBiUQs_dAQcuKWcwB-vRGlRFgLblg6pt5E6OQmsZ2-UMXzj0GbRhWpF2vX4U8qwy2uiHu1Q=s160-c-k-c0x00ffffff-no-rj'}
            alt="avatar"
            className="rounded-md"
            style={{
              width: 64,
              height: 64,
              objectFit: 'cover'
            }}
          />
          
          {/* Panel Info */}
          <div className="flex-1">
            <div className='flex flex-row items-center gap-x-2'>
              <h1 className="text-2xl font-bold text-[#001533] mb-2">{panelData.name}</h1>
            </div>
            <p className="text-gray-600 mb-4">{panelData.description}</p>
            
            {/* Stats */}
            <div className="flex gap-6 justify-center md:justify-start">
              <div className="text-center">
                <span className="block text-xl font-bold text-[#005599]">{panelData.member_count.toLocaleString()}</span>
                <span className="text-sm text-gray-500">Members</span>
              </div>
              <div className="text-center">
                <span className="block text-xl font-bold text-[#005599]">{panelData.follower_count.toLocaleString()}</span>
                <span className="text-sm text-gray-500">Followers</span>
              </div>
              <div className="text-center">
                <span className="block text-xl font-bold text-[#005599]">{panelData.upload_count.toLocaleString()}</span>
                <span className="text-sm text-gray-500">Posts</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Uploads Section */}
      <div className="bg-white rounded-lg p-6">
        <h2 className="text-xl font-bold text-[#001533] mb-6">Latest Uploads</h2>
        <div className="space-y-4">
          {allUploads.map((upload: any) => (
            <div 
              key={upload.id}
              onClick={() => setLocation(`/listen/${upload.file}`)}
              className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow cursor-pointer"
            >
              <div className="flex justify-between items-start mb-2">
                <h3 className="text-lg font-semibold text-[#003366]">{upload.title}</h3>
                <div className="flex gap-4">
                  <span className="text-sm text-gray-500">
                    {formatDate(upload.created)}
                  </span>
                  <span className="text-sm text-[#005599]">{formatDuration(upload.duration)}</span>
                </div>
              </div>
              <div className='flex flex-row items-center gap-x-2 pb-2'>
                <UserAvatar avatar={upload.avatar} username={upload.dname} size={24} />
                <span className='text-card-text font-semibold'>{upload.dname}</span>
                <span className='text-card-subtext'>@{upload.username}</span>
              </div>
              <p className="text-gray-600">{upload.description}</p>
            </div>
          ))}
        </div>

        {hasNextPage && (
          <div className="mt-4 text-center">
            <button
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
              className="px-4 py-2 bg-[#005599] text-white rounded-lg hover:bg-[#004488] disabled:opacity-50"
            >
              {isFetchingNextPage ? 'Loading more...' : 'Load More'}
            </button>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default UserProfileWithPodcasts;