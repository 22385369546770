import UserAvatar from "../common/UserAvatar";
import { formatRelative } from 'date-fns'
import { enUS } from 'date-fns/locale';

const formatRelativeLocale = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "MMM d, yyyy 'at' p"
};

const locale = {
  ...enUS,
  formatRelative: (token: string) => formatRelativeLocale[token as keyof typeof formatRelativeLocale],
};

export default function Comments({ comments }: { comments: any[] }) {
  return (
    <div>
    {comments.map((comment: any) => (
      <div className="flex flex-row mb-8">
        <UserAvatar 
          size={45}
          avatar={comment.profiles.avatar} 
          username={comment.profiles.dname} />
        <div className="flex flex-col flex-1 ml-3">
          <span className="font-semibold text-card-text">{comment.profiles.dname}<span className="text-slate-400 ml-1">@{comment.profiles.username}</span></span>
          <span className="text-[#9a9a9a] text-sm">{formatRelative(new Date(comment.created_at), new Date(), { locale })}</span>
          <p className="text-[#202020] mt-1">{comment.content}</p>
        </div>
      </div>
    ))}
    </div>
  );
}