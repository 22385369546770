import BaseButton from "./BaseButton";

export default function GoogleButton({ className = '', collapsible }: { className?: string, collapsible?: boolean }) {
  return (
    <BaseButton 
      className={className}
      link={"https://play.google.com/store/apps/details?id=fm.jacket.radio"} 
      IconImage={<img src="/gp.svg" alt="Play store icon" className='w-[38px]' />}
      text={"Google Play"} 
      subtext={"Get it on"}
      collapsible={collapsible} 
    />
  )
}