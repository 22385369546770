import BaseButton from "./BaseButton";

export default function AppleButton({ className = '', collapsible }: { className?: string, collapsible?: boolean }) {
  return (
    <BaseButton 
      className={className}
      link={"https://apps.apple.com/us/app/jacket-fm/id6651823841"} 
      IconImage={<img src="/ap.svg" alt="Apple store icon" className='w-[38px]' />}
      text={"App Store"} 
      subtext={"Download on the"} 
      collapsible={collapsible}
    />
  )
}