import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import supabase from '../../lib/supabase';
import VideoPlayer from '../VideoPlayer';
import { useRoute } from 'wouter';
import UserAvatar from '../common/UserAvatar';
import { format } from 'date-fns-tz';
import UploadTabs from './UploadTabs';

// Format given date or current date in human readable format - uses local timezone by default
const formatDate = (date = new Date()) => {
  return format(date, 'MMM dd, yyyy');
};

const CompactPlayer = () => {
  const [, params] = useRoute<{ id: string }>('/listen/:id');
  const initialTrack = params?.id ?? null;
  const [currentTrack, setCurrentTrack] = useState(0);
  const [progressPct, setProgressPct] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [seekTo, setSeekTo] = useState<number | null>(null);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const nextTrack = () => {
    setCurrentTrack(currentTrack + 1);
  };

  const prevTrack = () => {
    setCurrentTrack(currentTrack > 0 ? currentTrack - 1 : currentTrack);
  };

  const handleSeek = (percentage: number) => {
    setProgressPct(percentage);
    setSeekTo(percentage);
  };

  const { isLoading: commentsLoading, data: comments, refetch: refetchComments } = useQuery({
    queryKey: ['comments', { track: initialTrack || currentTrack }],
    queryFn: async () => {
      const {data, error} = await supabase
        .from('upload_comments')
        .select('*, profiles!profile_id(*)')
        .eq('upload_id', post.data.id);

      if(error) return [];

      return data as any;
    },
    enabled: false,
  });

  const { isLoading: postLoading, data: post } = useQuery({
    queryKey: ['playlist', { track: initialTrack || currentTrack }],
    queryFn: async () => {
      const result = await supabase
        .from('uploads')
        .select('*, profiles!profile_id(*), groups!group_id(*)')
        .eq('file', initialTrack)
        .single();
      return result as any;
    },
    staleTime: 365 * 24 * 60 * 60 * 1000,
    gcTime: 365 * 24 * 60 * 60 * 1000,
    refetchOnMount: false,
  });

  const containerEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if(!post?.data) return;
    document.title = `${post.data.title} | by ${post.data.profiles?.dname ?? post.data.dname} | Jacket.fm`;
    refetchComments();
  }, [post, refetchComments]);

  return (
    <div className="flex flex-col w-full p-4">
      {!postLoading && post?.data && (
        <div className="flex flex-col">
          <span className="font-semibold text-3xl text-[#004080] pb-2 px-2">{post.data.title}</span>
          <div className="flex flex-row mb-5 px-2 pt-2">
            <UserAvatar username={post.data.profiles?.dname ?? post.data.dname} size={50} />
            <div className="flex flex-col mx-2 flex-1 min-w-[0]">
              <a className="cursor-pointer hover:opacity-50" href={`/user/${post.data.profiles?.username ?? post.data.username}`}>
                <span className="text-card-text mr-2 truncate font-semibold">
                  {post.data.profiles?.dname ?? post.data.dname}
                </span>
                <span className="text-card-subtext truncate">
                  @{post.data.profiles?.username ?? post.data.username}
                </span>
              </a>
              <span className="text-[#9a9a9a]">
                {formatDate(post.data.created_at)} {post.data.groups && <a href={`/panels/${post.data.groups.slug}`} className="hover:opacity-50 before:content-['·']"> Shared in <b className='text-gradient-third'>{post.data.groups.name}</b></a>}
              </span>
            </div>
          </div>
        </div>
      )}
      <div
        ref={containerEl}
        className="rounded-2xl w-full max-w-2xl overflow-hidden"
        style={{
          background: "linear-gradient(135deg,#003366 0%,#005599 100%)"
        }}
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
            {postLoading && <span className="p-2">Your audio feed is loading...</span>}
            {!postLoading && post?.data === null && (
              <span className="p-2 px-10">Nothing to load, the database is empty.</span>
            )}
            {!postLoading && post?.data && (
              <div className="flex flex-col">
                
                {/* Seek Bar */}
                <div className="px-4 pt-4 w-full">
                  <div className="relative w-full">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={progressPct}
                      className="range-input"
                      onChange={(e) => handleSeek(Number(e.target.value))}
                    />
                    <div className="flex justify-between mt-1 text-xs text-white font-semibold">
                      <span>{formatTime(currentTime)}</span>
                      <span>{formatTime(duration)}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {!postLoading && post?.data && (
          <div className='flex flex-row justify-center text-center'>
          <VideoPlayer
            setProgressPct={setProgressPct}
            setCurrentTime={setCurrentTime}
            setDuration={setDuration}
            seekTo={seekTo}
            setSeekTo={setSeekTo}
            src={`https://fm-jacket-uploads.s3.us-west-2.amazonaws.com/${post.data.profile_id}/${post.data.file}.m3u8`}
            playNext={nextTrack}
            playPrev={prevTrack}
          />
          </div>
        )}
      </div>
      <UploadTabs comments={commentsLoading ? [] : comments ?? []} />
    </div>
  );
};

export default CompactPlayer;