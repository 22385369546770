export default function BaseButton({ link, IconImage, text, subtext, className = '', collapsible }: { 
  link: string, 
  IconImage: any,
  text: string, 
  subtext: string, 
  className?: string,
  collapsible?: boolean
}) {
  return (
    <a
      className={`${className} ${collapsible ? 'ml-2 md:ml-0 md:h-[60px] md:w-[180px] md:gap-2' : 'h-[60px] w-[180px] gap-2'} rounded-lg flex flex-row items-center px-2 bg-[#202020]`}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <span className={collapsible ? 'scale-50 md:scale-100' : ''}>
        {IconImage}
      </span>
      <div className="flex flex-col gap-0.5">
        <span className={`text-white text-xs ${collapsible && 'hidden md:block'}`}>{subtext}</span>
        <span className={`text-white font-bold ${collapsible ? 'text-sm md:text-lg' : 'text-lg'}`}>{text}</span>
      </div>
    </a>
  )
}