import { useQuery } from "@tanstack/react-query";
import supabase from "../lib/supabase";

export const useMemberData = (username: string) => {
  return useQuery<any, Error>({
    queryKey: ['member', username],
    queryFn: async () => {
      const { data, error } = await supabase
        .rpc('get_profile_with_counts_by_username', { username_param: username })
        .single();

      if (error) {
        console.error(error);
        throw error;
      }

      return data as any;
    },
    enabled: !!username
  });
};