import { useQuery } from '@tanstack/react-query';
import supabase from '../../lib/supabase';
import { useRoute } from 'wouter';

interface SubtitleLine {
  start: number;
  end: number;
  text: string;
}

export default function Transcript() {
  const [, params] = useRoute<{ id: string }>('/listen/:id');
  const fileId = params?.id ?? null;

  const transcriptQuery = useQuery({
    queryKey: ['transcript', fileId],
    queryFn: async () => {
      const { data: tData, error: tError } = await supabase
        .from('uploads')
        .select('*, transcripts!id(*)')
        .eq('file', fileId)
        .single();
      
      if (tError) throw tError;

      return {
        subtitles: JSON.parse(tData.transcripts.subtitle_data),
      };
    },
    enabled: !!fileId,
  });

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  if (transcriptQuery.isLoading) {
    return <div className="p-4">Loading transcript...</div>;
  }

  if (transcriptQuery.isError) {
    return <div className="p-4 text-red-500">Error loading transcript</div>;
  }

  return (
    <div className="max-w-3xl mx-auto pb-10 mb-10">
      {transcriptQuery.data?.subtitles.map((line: SubtitleLine) => (
        <div 
          key={line.start}
          className="flex flex-row items-start gap-x-5 px-4 py-2"
        >
          <div className="-ml-[75px] mt-0.5 bg-gray-200 rounded-full min-w-[60px] text-center">
            <span className="text-xs text-gray-600 font-semibold">
              {formatTime(line.start)}
            </span>
          </div>
          <div className="flex-1">
            <p className="text-[#242424] font-spectral text-xl">
              {line.text.trim()}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}