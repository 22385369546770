import AppleButton from '../buttons/AppleButton';
import GoogleButton from '../buttons/GoogleButton';
import CompactPlayer from '../post/CompactPlayer';

export default function Standalone() {
  return (
    <div className='bg-white w-screen h-screen overflow-y-auto'>
      <div className='max-w-[680px] mx-auto'>
        {/* Add this new div for app download links */}
        <div className="flex flex-row mt-4 mb-2 px-2 md:px-0 items-center justify-between max-w-2xl w-full">
          <a href="/" title='Jacket.fm home'>
            <img alt="Jacket.fm logo" src="/favicon.png" className="w-[38px] h-[38px]" />
          </a>
          <div className='flex flex-row md:gap-x-4'>
            <AppleButton collapsible />
            <GoogleButton collapsible />
          </div>
        </div>
        <CompactPlayer />
      </div>
    </div>
  );
}
