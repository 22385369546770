import React from 'react';
import { Route, Switch } from 'wouter';
import AudioForm from './components/pages/AudioForm';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Landing from './components/pages/Landing';
import Standalone from './components/pages/Standalone';
import EmbeddedPlayer from './components/pages/EmbeddedPlayer';
import Privacy from './components/pages/Privacy';
import DeleteAccount from './components/pages/DeleteAccount';
import UserProfile from './components/pages/UserProfile';
import Invite from './components/pages/Invite';
import PanelDetails from './components/pages/PanelDetails';
import LiveInvite from './components/pages/LiveInvite';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="w-screen h-screen overflow-x-hidden">
        <div className="flex flex-col items-center">
          <Switch>
            <Route path="/user/:username" component={UserProfile} />
            <Route path="/panels/:slug" component={PanelDetails} />
            <Route path="/embed/:id" component={EmbeddedPlayer} />
            <Route path="/listen/:id" component={Standalone} />
            <Route path="/create" component={AudioForm} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/delete" component={DeleteAccount} />
            <Route path="/invite" component={Invite} />
            <Route path="/live" component={LiveInvite} />
            <Route path="/" component={Landing} />
            <Route>404: No such page!</Route>
          </Switch>
        </div>
      </div>
    </QueryClientProvider>
  );
}

export default App;
