import { useQuery } from "@tanstack/react-query";
import supabase from "../lib/supabase";

export const usePanelData = (slug: string) => {
  return useQuery<any, Error>({
    queryKey: ['panel', slug],
    queryFn: async () => {
      const { data, error } = await supabase
        .rpc('get_group_details_by_slug', { slug_param: slug })
        .single();

      if (error) {
        console.error(error);
        throw error;
      }

      return data as any;
    },
    enabled: !!slug
  });
};