import React from 'react';

interface UserAvatarProps {
  size?: number;
  avatar?: string | null;
  username: string;
}

const AVATAR_COLORS = [
  'hsl(215, 50%, 55%)',  // Pleasant blue
  'hsl(149, 45%, 45%)',  // Sage green
  'hsl(195, 45%, 45%)',  // Steel blue
  'hsl(355, 50%, 55%)',  // Soft red
  'hsl(260, 40%, 55%)',  // Muted purple
  'hsl(175, 45%, 45%)',  // Teal
  'hsl(25, 50%, 55%)',   // Warm orange
  'hsl(235, 45%, 55%)',  // Royal blue
  'hsl(335, 45%, 55%)',  // Rose
  'hsl(165, 40%, 45%)',  // Sea green
];

const UserAvatar: React.FC<UserAvatarProps> = ({ size = 32, avatar, username }) => {
  // Get consistent index for a username
  const getColorIndex = (name: string): number => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash % AVATAR_COLORS.length);
  };

  // Get initials from username
  const getInitials = (name: string): string => {
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase()
      .slice(0, 1);
  };

  if (avatar && avatar !== 'https://yt3.googleusercontent.com/GgzUBiUQs_dAQcuKWcwB-vRGlRFgLblg6pt5E6OQmsZ2-UMXzj0GbRhWpF2vX4U8qwy2uiHu1Q=s160-c-k-c0x00ffffff-no-rj') {
    return (
      <img
        src={avatar}
        alt="avatar"
        className="rounded-full"
        style={{
          width: size,
          height: size,
          objectFit: 'cover'
        }}
      />
    );
  } else if(!username) {
    return null;
  }

  const backgroundColor = AVATAR_COLORS[getColorIndex(username)];
  const initials = getInitials(username);

  return (
    <div
      aria-label="avatar"
      className="rounded-full flex items-center justify-center"
      style={{
        width: size,
        height: size,
        backgroundColor,
      }}
    >
      <span
        className="text-white font-medium"
        style={{
          fontSize: `${size * 0.6}px`,
          lineHeight: 1,
        }}
      >
        {initials}
      </span>
    </div>
  );
};

export default UserAvatar;